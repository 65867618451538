import store from '@/store'
import { bigPlankTemp } from '@/util/bigPlankTemp'
import { judgeRectangle } from '@/util/commonFun'
import { change_plank_dir } from '@/util/countPlankDir'
import { getPartFlag, judgePtpNeedBackNum } from '@/util/createTag'
import { getPlateKnifeDiameter } from '@/util/dealPaibanData'
import defaultTagData from '@/util/defaultTagData'
import { getDeviceXDPI, mmToPx, pxTomm } from '@/util/exportFuncs'
import { defineObjProp } from '@/util/plankCommonFuncs'
import { PxToPt } from '@/util/plankCommonFuncs'
import { judgeVHSlot } from '@/util/plankCommonFuncs'
import { genHandleSlopesStr } from '@/util/plankCommonFuncs'
import {
  SURPLUS_TAG_TEXT_OPTIONS,
  TEXT_OPTIONS,
  dealNotchDir,
  getEdgeValue,
  getGraghEdge,
  getPaibanTime,
  getSurplusSize,
  updateEdgeInfo,
} from '@/util/tag'
import {
  DocCombinationShape,
  dealElementWH,
  dealSurplusPlank,
  drawEdgeAndOtherInfo,
  drawPartHoleSlot,
  drawSpecialSign,
  fontFamilyMap,
  getArrayItem,
  highPart,
  jsPdfTable,
  setRotate,
  xyScale,
} from '@/util/tag/pdf-draw'
import { dealTextStr } from '@/util/tag/tag-draw'
import BwipJs from 'bwip-js'
import JsBarcode from 'jsbarcode'
import JsPDF from 'jspdf'
import { cloneDeep } from 'lodash'
import QRCode from 'qrcode'

let CONFIG = {}
const dpi = getDeviceXDPI()
let FIVE_SIX_DIR = false
let NC_SETTING = JSON.parse(JSON.stringify(store.state.ncSetting))
let JX_CHECKED = false
let isRotatePlank = false

// 纹理映射
const texDirMap = {
  notcare: '无纹理',
  normal: '竖纹',
  reverse: '横纹',
}

function initConfig(config) {
  if (!config.scale) config.scale = 1
  if (!config.backgroundColor) config.backgroundColor = '#fff'
  if (!config.type) config.type = 'svg'
  if (!config.canvas) config.canvas = 'tag'
  return config
}
export default class CreatePdf {
  constructor(temp, cb) {
    this.tagWidth = temp.tag_width
    this.tagHeight = temp.tag_height
    this.rLeft = temp.rectInfo.left
    this.rTop = temp.rectInfo.top
    this.template = temp
    this.temp = temp.tem_data
    this.doc = new JsPDF(this.tagWidth / this.tagHeight > 1 ? 'l' : 'p', 'mm', [
      this.tagWidth,
      this.tagHeight,
    ])
    this.cb = cb
  }
  savePdf(template_name) {
    this.doc.save(`${template_name}.pdf`)
  }
  toBase64() {
    return this.doc.output('dataurlstring').substring(28)
  }
  output(type) {
    return this.doc.output(type)
  }
  /** 需要用到generator函数来调用create函数 所以需要返回当前类的指向 */
  getInstance() {
    return this
  }
}
/**
 *
 * @param {object} bigPlank 大板信息
 */

CreatePdf.prototype.create = function* (
  bigPlank,
  tagList,
  laveList,
  config = {},
  five_six_dir,
  that,
  sort_value,
  isTemp,
  nc_setting,
  showCutTag,
  rotatePlank,
  jx_checked
) {
  // 取出统计好的房间，柜体比编号
  const { roomNameMap: roomAndCabinetMap } = config
  isRotatePlank = rotatePlank
  CONFIG = initConfig(config)
  NC_SETTING = nc_setting ? JSON.parse(JSON.stringify(nc_setting)) : {}
  JX_CHECKED = jx_checked
  const PRINT_COLOR_TAG = config.print_color_tag ?? false
  const judgeLimit = config.judgeLimit ?? true
  if (isTemp) NC_SETTING.xyReverse = false
  FIVE_SIX_DIR = five_six_dir
  const doc = that.doc
  let rectL = that.template.rectInfo.left
  let rectT = that.template.rectInfo.top

  let rectLOfPlank = bigPlankTemp.rectInfo.left
  let rectTOfPlank = bigPlankTemp.rectInfo.top
  doc.setFont('msyh') // msyh simhei simsun
  // 这儿的temp就是模板里面的tem_data
  let tempData = that.temp
  const specialMarkParList = []

  // 最后添加一个不会显示的文字，由于其他分支处理后二维码在最后一个绘制会出问题
  const tempD = {
    data: {
      fontPt: 8,
      fontSize: 10,
      left: 9999,
      top: 9999,
      text: '.',
    },
    type: 'FixedText',
  }
  tempData = [...tempData, tempD]

  // 大板标签的tem_data
  const tempDataOfBigPlnak = [...bigPlankTemp.tem_data]
  let totalLength = tagList.length + laveList.length
  for (let i = 0; i < tagList.length; i++) {
    const msgItem = tagList[i]
    const part = msgItem.part
    if (msgItem.plank) {
      const { size } = getGraghEdge(msgItem.plank, store.state.ncSetting)
      msgItem.plank['plankSize'] = size
    }
    // 余料板件单独处理
    if (part.specialType == 'supplus' && msgItem.type !== 'plank') {
      if (showCutTag) {
        yield new_createLavePdf(
          doc,
          that.template,
          part,
          msgItem.plank,
          msgItem.plank_index,
          totalLength,
          nc_setting,
          i
        )
      } else {
        continue
      }
      // 普通板件以及大板标签
    } else {
      // 如果开启了彩色标签设置
      if (PRINT_COLOR_TAG && !judgeLimit && msgItem.type !== 'plank') {
        const length = pxTomm(1)
        // 订单 40/60 15/40 是需求文档里面的颜色块比例
        const template = that.template
        drawColorRect(
          0,
          0,
          scaleSize((40 / 60) * template.tag_width) - length,
          scaleSize((15 / 40) * template.tag_height) - length,
          part?.colorRes?.orderNo ?? 'rgb(255, 255, 255)',
          doc
        )
        // 房间
        drawColorRect(
          scaleSize((15 / 40) * template.tag_height) + length,
          0,
          scaleSize((40 / 60) * template.tag_width) - length,
          scaleSize((25 / 40) * template.tag_height) - length,
          part?.colorRes?.roomName ?? 'rgb(255, 255, 255)',
          doc
        )
        // 单元柜体
        drawColorRect(
          0,
          scaleSize((40 / 60) * template.tag_width) + length,
          scaleSize((20 / 60) * template.tag_width) - length,
          scaleSize((30 / 40) * template.tag_height) - length,
          part?.colorRes?.loc ?? 'rgb(255, 255, 255)',
          doc
        )
        // 门板
        drawColorRect(
          scaleSize((30 / 40) * template.tag_height) + length,
          scaleSize((40 / 60) * template.tag_width) + length,
          scaleSize((20 / 60) * template.tag_width) - length,
          scaleSize((10 / 40) * template.tag_height) - length,
          part.type == 'Plank' ? 'rgb(255, 255, 255)' : '#DCDCDC',
          doc
        )
      }
      // for (let j = 0; j < partTotal; j++) {
      // partIndex++
      // const part = plank.parts[j]
      /** 是否需要生成二维码及条形码 */
      // 正反面孔
      let holes = part.holes
      // 正反面槽
      let slots = part.slots
      // 所有孔槽
      const allHoleSlot = [...holes, ...slots]
      // 正面孔
      let frontH = holes.filter((v) => {
        return Number(v.side) === 1
      }).length
      // 背面孔
      let backH = holes.filter((v) => {
        return Number(v.side) === -1
      }).length
      // 侧孔
      let sholesL = part.sholes.length
      // 正面槽
      let fSlots = part.slots.filter((v) => {
        return Number(v.side) === 1
      }).length
      // 背面槽
      let bSlots = part.slots.filter((item) => {
        return Number(item.side) === -1
      }).length
      // 侧槽
      let sSlots = part.sslots.length
      const currentTempData =
        msgItem.type === 'plank' ? tempDataOfBigPlnak : tempData

      const currentTemplate =
        msgItem.type === 'plank' ? bigPlankTemp : that.template

      // 计算缩放值-大板标签进行整体缩放
      const scaleW = that.template.tag_width / 60
      const scaleH = that.template.tag_height / 40
      let finalScale = 1
      if (scaleW === scaleH) {
        finalScale = scaleW
      } else if (scaleW > scaleH) {
        finalScale = scaleH
      } else {
        finalScale = scaleW
      }

      // 双面加工大板标签添加翻面标识
      if (msgItem.type === 'plank' && msgItem.plank.ableToggle) {
        doc.circle(
          pxTomm(148 * CONFIG.scale, dpi) * finalScale,
          pxTomm(86 * CONFIG.scale, dpi) * finalScale,
          pxTomm(15 * CONFIG.scale, dpi) * finalScale
        )
        doc.setFontSize(12 * finalScale)
        doc.text(
          pxTomm(140.5 * CONFIG.scale, dpi) * finalScale,
          (pxTomm(84 * CONFIG.scale, dpi) - 1.5) * finalScale,
          '翻',
          {
            baseline: 'top',
          }
        )
      }

      for (const item of currentTempData) {
        yield new Promise((r) => r()).then(async () => {
          const info = item.data
          let x = ''
          let y = ''
          if (msgItem.type === 'plank') {
            x = pxTomm(info.left - rectLOfPlank, dpi) * finalScale
            y = pxTomm(info.top - rectTOfPlank, dpi) * finalScale
            doc.setFontSize((info.fontPt - 1) * finalScale)
          } else {
            x = pxTomm(info.left - rectL, dpi)
            /** 此处+2是因为不加的画位置不对 看不见 */
            y = pxTomm(info.top - rectT, dpi)
            doc.setFontSize(info.fontPt - 1)
          }
          let str = ''
          switch (item.type) {
            case 'holeSlotPicture':
              drawPartHoleSlot(
                doc,
                part,
                x,
                y,
                info,
                {
                  tempData: currentTempData,
                  CONFIG,
                  xyReverse: NC_SETTING.xyReverse,
                },
                JX_CHECKED
              )
              break
            case 'FixedText':
              str = info.text
              doc.setFont(fontFamilyMap[info.fontFamily])
              break
            case 'Graphics':
              drawGraphics(doc, info, x, y, that.rLeft, that.rTop)
              break

            case 'DataSource':
              {
                if (msgItem.type === 'plank') {
                  if (info.source_data === 'ncName') {
                    str = msgItem.plank.isGenNcSuffix
                      ? msgItem.plank.plankDrawMsg[info.source_data]
                      : msgItem.plank.plankDrawMsg[info.source_data].slice(
                          0,
                          msgItem.plank.plankDrawMsg[
                            info.source_data
                          ].lastIndexOf('.')
                        )
                  } else {
                    str = msgItem.plank.plankDrawMsg[info.source_data]
                  }

                  if (['size'].includes(info.source_data)) {
                    const textArr = str.split('*')
                    str = `${textArr[1]}*${textArr[0]}*${textArr[2]}`
                  }
                } else {
                  if (info.source_data === 'plankSize') {
                    const textArr = msgItem.plank[info.source_data].split('*')
                    str = `${textArr[1]}*${textArr[0]}*${textArr[2]}`
                  } else {
                    str = getDataSourceVal(
                      doc,
                      info,
                      part,
                      msgItem.plank_index,
                      i + 1,
                      totalLength,
                      that.template,
                      info,
                      that,
                      i,
                      config
                    )
                  }
                }
                const { text: newStr, fontSize: fts } = dealTextStr(
                  str,
                  info.fontSize,
                  info.rectWidth ?? 80,
                  info.rectHeight ?? 11.3,
                  info.isAutoFontSize ?? true
                )
                str = newStr
                doc.setFont(fontFamilyMap[info.fontFamily])
                doc.setFontSize(
                  msgItem.type === 'plank'
                    ? PxToPt(fts) * finalScale
                    : PxToPt(fts)
                )
              }

              break
            case 'specialMark':
              // 自定义特殊标记比率 为什么是0.8 因为特殊标签默认高宽为37  50 / 37 = 0.8
              const [custSpeciialTagratioX, custSpeciialTagratioY] = [
                pxTomm(0.8 * info.scaleX),
                pxTomm(0.8 * info.scaleY),
              ]
              const opacity = info.opacity
              // 组装参数 标签模板和柜柜跳转至此的情况都需要展示自定义特殊标签
              specialMarkParList.push({
                doc,
                sign: part?.specialFlag ?? part.specTagTemplate,
                msgItem,
                opacity,
                sizeAuto: true,
                size: 1,
                op: {
                  startX: x,
                  startY: y,
                  custSpeciialTagratioX,
                  custSpeciialTagratioY,
                },
              })
            case 'QRcode':
              let url = ''
              if (msgItem.type === 'plank') {
                url = !(
                  info.source_data === 'qrCode' && !msgItem.plank.isGenNcSuffix
                )
                  ? msgItem.plank.plankDrawMsg[info.source_data]
                  : msgItem.plank.plankDrawMsg[info.source_data].slice(
                      0,
                      msgItem.plank.plankDrawMsg[info.source_data].lastIndexOf(
                        '.'
                      )
                    )
              } else {
                // 有反面标签
                if (info.source_data === 'plankNum') {
                  if (frontH || sholesL || fSlots) url = part[info.source_data]
                } else if (info.source_data === 'oriPlankNum') {
                  if (info.showCode) url = part[info.source_data]
                  else if (
                    frontH ||
                    backH ||
                    sholesL ||
                    (slots && slots.length) ||
                    sSlots
                  )
                    url = part.oriPlankNum
                } else if (info.source_data === 'oriPlankNumNoMark') {
                  if (info.showCode) url = part[info.source_data]
                  else if (
                    frontH ||
                    backH ||
                    sholesL ||
                    (slots && slots.length) ||
                    sSlots
                  )
                    url = part.oriPlankNumNoMark
                } else if (info.source_data === 'ggid') {
                  url = part['ggid']
                } else if (
                  info.source_data === 'remarks' ||
                  info.source_data === 'remark'
                ) {
                  if (!!part[info.source_data]) {
                    let hasMoreThanAscii = [...part[info.source_data]].some(
                      (char) => char.charCodeAt(0) > 127
                    )
                    url = part[info.source_data]
                    if (
                      hasMoreThanAscii ||
                      part[info.source_data].length > 50
                    ) {
                      url = ''
                      str = '不支持该数据'
                      doc.setFont(fontFamilyMap[info.fontFamily])
                      doc.setFontSize(7)
                    }
                  }
                } else if (info.source_data === 'ncName') {
                  url = info.isQRcodeNCSuffix
                    ? part.ncName
                    : part.ncName.substring(0, part.ncName.lastIndexOf('.'))
                } else if (info.source_data === 'plankNumF') {
                  if (backH || bSlots) url = part.oriPlankNumF
                } else if (info.source_data === 'size') {
                  const { thick } = part
                  let { height, width } = part['oRect']
                  if (nc_setting.xyReverse) {
                    ;[width, height] = [height, width]
                  }
                  url = `A${height}-B${width}-C${thick}`
                } else break
              }

              if (!url) break
              /** 这里给y减去2mm是因为 位置不太对 */
              await drawQrCode(
                url,
                info,
                doc,
                x,
                y,
                msgItem.type === 'plank'
                  ? info.mWidth * finalScale
                  : info.mWidth,
                msgItem.type === 'plank'
                  ? info.mHeight * finalScale
                  : info.mHeight
              )
              break

            case 'Onecode':
              let plankNumV = '',
                simplePlankNum = part.simplePlankNum
              const { front_file_identifier, opposite_file_identifier } =
                store.state.ncSetting
              if (info.source_data === 'plankNum') {
                if (frontH || sholesL || fSlots) {
                  plankNumV = part.plankNum
                  simplePlankNum += front_file_identifier ?? ''
                  const flag = allHoleSlot.every((item) => item.side == -1)
                  if (flag && allHoleSlot.length) {
                    break
                  }
                }
              } else if (info.source_data === 'plankNumF') {
                if (backH || bSlots || judgePtpNeedBackNum(sSlots, sholesL)) {
                  simplePlankNum += opposite_file_identifier ?? ''
                  plankNumV = part.oriPlankNumF
                  const flag = allHoleSlot.every((item) => item.side == 1)
                  if (
                    flag &&
                    !judgePtpNeedBackNum(sSlots, sholesL) &&
                    allHoleSlot.length
                  ) {
                    break
                  }
                }
              } else if (info.source_data === 'oriPlankNum') {
                if (info.showCode) {
                  plankNumV = part.oriPlankNum
                } else {
                  // 只有有孔槽才显示条码
                  if (
                    frontH ||
                    backH ||
                    sholesL ||
                    (slots && slots.length) ||
                    sSlots
                  ) {
                    plankNumV = part.oriPlankNum
                  }
                }
              } else if (info.source_data === 'oriPlankNumNoMark') {
                if (info.showCode) {
                  plankNumV = part.oriPlankNumNoMark
                } else {
                  // 只有有孔槽才显示条码
                  if (
                    frontH ||
                    backH ||
                    sholesL ||
                    (slots && slots.length) ||
                    sSlots
                  ) {
                    plankNumV = part.oriPlankNumNoMark
                  }
                }
              } else if (info.source_data === 'remarks') {
                plankNumV = part.remarks
              } else if (info.source_data === 'remark') {
                if (!!part.remark) {
                  let hasMoreThanAscii = [...part.remark].some(
                    (char) => char.charCodeAt(0) > 127
                  )
                  plankNumV = part[info.source_data]
                  if (hasMoreThanAscii || part[info.source_data].length > 50) {
                    plankNumV = ''
                    str = '不支持该数据'
                    doc.setFont(fontFamilyMap[info.fontFamily])
                    doc.setFontSize(7)
                  }
                }
              } else if (info.source_data === 'ncName') {
                plankNumV = info.isOnecodeNCSuffix
                  ? part.ncName
                  : part.ncName.substring(0, part.ncName.lastIndexOf('.'))
              } else if (info.source_data === 'plankUniqueNum') {
                plankNumV = part.plankUniqueNum
              } else break
              if (!plankNumV) break
              plankNumV += ''
              if (
                nc_setting.genSimpleLabelBarcode &&
                info.source_data !== 'ncName' &&
                info.source_data !== 'remark'
              ) {
                plankNumV = simplePlankNum
              }
              try {
                const brcode = drawBrCode(plankNumV, info)
                /** 高度 * 0.75 主要是如果生成条形码不给文字，默认高度会占100% 文字将会放不下 */
                // 宽度 * 0.98 是因为pdf生成的条形码会比图片中二维码要长一点
                let rX = x
                let rY = y
                const scaleX = info.mWidth / 32.54
                const scaleY = info.mHeight / 9.52
                const textScale = scaleX < scaleY ? scaleX : scaleY
                const textWidth = doc.getStringUnitWidth(plankNumV)
                /** 不能确定文本的宽度 所以从条形码的宽度4/1开始画 */
                const d = info.mWidth / 4 - textWidth / 2 // 条码左侧距条码左侧的距离
                let textStartX = x + d + 2.2
                /** 文本的高度也不能确定  所以在画出来的基础上让文字多向下1.5mm*/
                let textStartY =
                  y + info.mHeight * 0.75 + info.lineHeight + 1.5 * textScale
                let rotateDeg = info.rotateDeg
                const { mHeight, mWidth } = info
                const mh = mHeight * 0.75
                const mw = mWidth * 0.98
                switch (info.rotateDeg) {
                  case 90:
                    rotateDeg = -90
                    rX = x + (mw - mh) / 2
                    rY = y - (mw + mh) / 2
                    textStartX = rX - 1.5 * 2
                    textStartY = rY + mh + d + 1.5
                    break
                  case 180:
                    rX = x + mw
                    rY = y - mh
                    textStartX = rX - d - textWidth
                    textStartY = rY + (mh - 1.5 * 2)
                    rotateDeg = 180
                    break
                  case 270:
                    rotateDeg = 90
                    rX = x + (mw + mh) / 2
                    rY = y + (mw - mh) / 2
                    textStartX = rX + 1.5 * 2
                    textStartY = rY + mh - d - 1.5
                    break

                  default:
                    break
                }

                doc.addImage(
                  brcode.url,
                  rX,
                  rY,
                  info.mWidth * 0.98,
                  info.mHeight * 0.75,
                  '',
                  '',
                  rotateDeg
                )
                // ctx.rotate(-90)

                doc.setFont(fontFamilyMap[info.fontFamily])
                doc.setFontSize((info.fontPt - 1) * textScale)
                doc.text(plankNumV, textStartX, textStartY, {
                  angle: rotateDeg,
                })
              } catch (error) {
                console.error(error)
              }
              break

            case 'Typography':
              drawBigPlank(
                doc,
                msgItem.plank,
                part,
                msgItem.part_index,
                info,
                msgItem.type === 'plank' ? rectLOfPlank : rectL,
                msgItem.type === 'plank' ? rectTOfPlank : rectT,
                x,
                y,
                currentTemplate,
                sort_value,
                finalScale
              )
              break

            case 'wardrobeNum':
              // const field = info.field ?? 'wardrobeNum'
              // let showText = ''
              //柜体序号
              const { field, roomSortWay } = info
              const { roomName, loc } = part
              const textValue = getPartFlag(
                { field, roomSortWay, part },
                { roomName, loc },
                roomAndCabinetMap
              )
              // 不需要显示面的时候，阻止canvas绘制
              if (!textValue) break
              drawWardrobeNum(doc, info, x, y, textValue)
              break
            case 'Table':
              let tableData = []
              if (
                info.source_data === 'extraParts' &&
                part.extraParts &&
                part.extraParts.length
              ) {
                tableData = part.extraParts.map((e) => [
                  e.name,
                  e.spec,
                  e.num + '',
                ])
              }
              const { fontSize, source_data, fontFamily } = item.data
              jsPdfTable(
                doc,
                tableData,
                fontSize,
                source_data,
                y,
                x,
                fontFamily
              )

              break
          }
          if (str) {
            doc.text(str, x, y, { baseline: 'top' })
          }
        })
      }
    }
    // 特殊标记
    const opacity = store.state.drawOpacity
    const sizeAuto = store.state.drawSizeAuto
    const size = store.state.drawSize
    // 判断模板中是否有自定义特殊标签
    if (specialMarkParList.length <= 0) {
      drawSpecialSign(doc, part.specialFlag, msgItem, opacity, sizeAuto, size)
    } else {
      // 循环调用drawSpecialSign
      specialMarkParList.forEach((param) => {
        const { doc, sign, msgItem, opacity, sizeAuto, size, op } = param
        drawSpecialSign(doc, sign, msgItem, opacity, sizeAuto, size, op)
      })
      specialMarkParList.length = 0
    }
    /** 最后一个循环不增加页数 */
    // if (j < partTotal - 1) {
    //   if (that.cb) {
    //     that.cb()
    //   }
    //   doc.addPage()
    // }
    // }
    /** 循环结束添加一页 */
    if (i < tagList.length - 1) {
      doc.addPage()
      if (that.cb) {
        that.cb()
      }
    }
  }
  /** 有余料则进行余料的绘制 */
  let total_part = tagList.length + laveList.length
  if (laveList.length !== 0) {
    if (!tagList.length) doc.deletePage(1)
    for (let i = 0; i < bigPlank.length; i++) {
      doc.addPage()
      if (that.cb) {
        that.cb()
      }
      yield createLavePdf(
        doc,
        that.template,
        bigPlank[i],
        i,
        {},
        store.state.ncSetting,
        total_part,
        tagList.length
      )
    }
  }
}

function getDataSourceVal(
  doc,
  tempData,
  part,
  plankIndex,
  partIndex,
  totalLength,
  template,
  currentTemp,
  that,
  label_num,
  config
) {
  if (!tempData.source_data) return ''
  let result = ''
  switch (tempData.source_data) {
    case 'plank_index':
      result = `第${plankIndex}张大板`
      break
    case 'lable_index':
      result = `第${label_num + 1}/${totalLength}页`
      break
    case 'partName':
      if (part[tempData.source_data]) {
        const res = part[tempData.source_data].split('_')
        if (res.length <= 2 || (res.length === 3 && res[2] === '')) {
          result = res.join('_')
        } else {
          result = res.slice(2).join('_')
        }
      }
      break
    case 'plankID':
      result = part[tempData.source_data]
        ? `${part[tempData.source_data]}#`
        : ''
      break
    case 'onlyPlankID':
      result = part['plankID'] ? part['plankID'] : ''
      break
    case 'handleSlopes':
      let str = genHandleSlopesStr(part)
      result = part['handleSlopes'] ? str.replace('\n', '') : ''
      break
    case 'frontProcess':
      const frontArr = [...part.holes, ...part.slots]
      const frontRes = frontArr.some((item) => item.side == 1)
      if (frontRes) {
        result = '加工正面'
      }
      break
    case 'partsChildren':
      const hingeTypeList = ['铰链', '普通铰链', '一字底座铰链']
      if (part.partsChildren && part.partsChildren.length) {
        let isExist = hingeTypeList.includes(part.partsChildren[0].name)
        if (isExist) {
          let hingeString =
            part.partsChildren[0].matSpec +
            '_' +
            part.partsChildren[0].maskPartName
          result = hingeString
        }
      }
      break
    case 'reverseProcess':
      const reversArr = [...part.holes, ...part.slots]
      const reversRes = reversArr.some((item) => item.side == -1)
      if (reversRes) {
        result = '加工反面'
      }
      break
    case 'sideProcess':
      const sideRes = part.sholes.length || part.sslots.length
      if (sideRes) {
        result = '加工侧面'
      }
      break
    case 'oSize':
      if (tempData.sizeRestrict) {
        result = part.oSize
          .split('*')
          .map((size) => parseInt(size))
          .join('*')
      } else {
        result = part[tempData.source_data] || ''
      }
      break
    case 'oSize_L':
      result = part.oSize.split('*').slice(0, 2).join('*')
      break
    case 'oSize_W_L_T':
      const tempList = part.oSize.split('*')
      result = `${tempList[1]}*${tempList[0]}*${tempList[2]}`
      break
    case 'cutSize':
      if (tempData.sizeRestrict) {
        result = handleSizeNum(part[tempData.source_data])
      } else {
        result = part[tempData.source_data] || ''
      }
      break
    case 'doorSize':
      result = handleSizeNum(part[tempData.source_data])
      break
    case 'oriPlankNum':
      result = store.state.ncSetting.genSimpleLabelBarcode
        ? part.simplePlankNum
        : part[tempData.source_data]
      break
    case 'size':
      result = handleSizeNum(part[tempData.source_data])
      break
    case 'bigPlankID':
      result = plankIndex + ''
      break
    case 'paibanTime':
      // let time = store.state.paibanTime
      //   ? store.state.paibanTime
      //   : new Date().getTime()
      // const date = new Date(time)
      // const Y = date.getFullYear() + '-'
      // const M =
      //   (date.getMonth() + 1 < 10
      //     ? '0' + (date.getMonth() + 1)
      //     : date.getMonth() + 1) + '-'
      // const D = date.getDate() + ' '
      // const h = date.getHours() + ':'
      // const m = date.getMinutes() + ':'
      // const s = date.getSeconds()
      // result = Y + M + D + h + m + s
      result = getPaibanTime()
      break
    case 'plankSignBox':
      const sign = template.tem_data.find(
        (item) =>
          item.data.source_data == 'plankSign' &&
          item.data.item_id == currentTemp.item_id
      )
      if (!sign) break
      let { fontPt, fontSize, fontWeight, lineHeight } = sign.data
      let t = new fabric.Text(sign.data.text, {
        fontPt,
        fontSize,
        fontWeight,
        lineHeight,
      })

      let px = pxTomm(sign.data.left - that.rLeft, dpi)
      let py = pxTomm(sign.data.top - that.rTop, dpi)
      const k = pxTomm(pxTomm(60, dpi), dpi)
      const tw = pxTomm(t.width, dpi) + k / 2
      const th = pxTomm(t.height, dpi) + k / 2
      const offset = fontSize < 8 ? 3 : 2
      px -= k / offset
      py -= k / offset
      doc.rect(px, py, tw, th)
      break
    case 'plankSign':
      result = '板件标注'
      break
    case 'sholeInfoF':
    case 'sslotInfoF':
      const sourceData = tempData.source_data
      result =
        part[sourceData] && part[sourceData].length !== 2
          ? part[sourceData]
          : ''
      break
    case 'process_label':
      const processMarkArr = []
      const remarksOptions = store.state.specialRemarks?.remarksOptions
      if (
        !remarksOptions ||
        Object.values(remarksOptions).every((item) => item)
      ) {
        part[tempData.source_data]?.auto_label?.forEach((item) => {
          processMarkArr.push(Object.values(item)[0])
        })
      } else {
        part[tempData.source_data]?.auto_label?.forEach((item) => {
          if (remarksOptions[Object.keys(item)[0]]) {
            processMarkArr.push(Object.values(item)[0])
          }
        })
      }
      part[tempData.source_data]?.custom_label?.forEach((item) => {
        processMarkArr.push(item)
      })
      result = processMarkArr.join('/')
      break
    case 'hsInfoTotal':
      result = part.hsInfo || '0孔0槽'
      break
    case 'hingeHoleLocationData':
      if (part.holes.length !== 0) {
        let xList = []
        let yList = []
        let arr = []
        for (let item of part.holes) {
          // 判断为铰链孔
          if (item.symbol == 'HINGE') {
            // 判断当前孔的x，y的距离
            xList.push(Number(item.ocenter.x.toFixed(2))) //距y轴的距离
            yList.push(Number(item.ocenter.y.toFixed(2))) //距x轴的距离
          }
        }
        if (!xList.length && !yList.length) {
          result = ''
          break
        }
        // 判断当前铰链孔在与y轴平行的线上还是与x轴平行的线上
        let direction
        let firstEdge
        if (xList.length === 1 && yList.length === 1) {
          let jz = xList[0]
          let jy = part.oRect.width - xList[0]
          let js = part.oRect.height - yList[0]
          let jx = yList[0]
          let minSort = [jz, jy, js, jx].sort((a, b) => {
            return a - b
          })[0]
          if (minSort === jz || minSort === jy) {
            arr = yList
            direction = 'vertical'
          } else {
            arr = xList
            direction = 'transverse'
            arr = arr.map((item) => {
              return part.oRect.width - item
            })
          }
        } else {
          if (
            xList.length === [...new Set(xList)].length &&
            yList.length !== [...new Set(yList)].length
          ) {
            // 铰链孔在与x轴平行的线上
            arr = xList
            direction = 'transverse'
            arr = arr.map((item) => {
              return part.oRect.width - item
            })
          } else {
            // 铰链孔在与y轴平行的线上
            arr = yList
            direction = 'vertical'
          }
        }
        let closeEdge
        // 判断距边的上下左右
        if (direction === 'transverse') {
          if (part.oRect.height - yList[0] > yList[0]) {
            closeEdge = '距上' + yList[0].toFixed(2)
          } else {
            closeEdge = '距下' + (part.oRect.height - yList[0]).toFixed(2)
          }
          firstEdge = '距右'
        } else {
          if (part.oRect.width - xList[0] > xList[0]) {
            closeEdge = '距左' + xList[0].toFixed(2)
          } else {
            closeEdge = '距右' + (part.oRect.width - xList[0]).toFixed(2)
          }
          firstEdge = '距上'
        }
        arr = arr.sort((a, b) => {
          return a - b
        })
        arr = arr.map((item) => {
          return item.toFixed(2)
        })
        result = firstEdge + arr.join(' ') + ' ' + closeEdge
      } else {
        result = ''
      }
      break
    // 铰链位置
    case 'hingeInfo':
      // 对hingeInfo的数据四舍五入保留两位 双开距上，竖双距右
      // 可以通过openDir进行判断当前的开门方向
      if (!part.openDir) {
        result = part?.hingeInfo?.map((item) => item.toFixed(2)).join(' ') || ''
        break
      }
      result =
        (['左开', '右开'].includes(part.openDir) ? '距下' : '距左') +
          part?.hingeInfo?.map((item) => item.toFixed(2)).join(' ') || ''
      break
    case 'edgeInfomation':
      result = part?.edgeInfo || ''
      break
    case 'texDir':
      result = texDirMap[part[tempData.source_data]]
      break
    case 'connection_types': // 大板连接方式
      let arr = []
      part[tempData.source_data] &&
        Object.values(part[tempData.source_data]).forEach((item) => {
          if (item && !arr.includes(item)) arr.push(item)
        })
      result = arr.join('、')
      break
    case 'sameSizeBoardCount':
      // 首先判断当前的标签是否符合展示要求，不符合返回''
      // 这里的标签都是小板标签 所以只需要排除轮廓不是矩形的异形板就可以了
      let flag = false
      if (part.path) {
        let path = cloneDeep(part.path[0])
        path.splice(-1)
        if (path.length !== 4 || !judgeRectangle(path)) {
          flag = true
        }
      }
      if (flag) {
        result = ''
      } else {
        const statisticsCount = config.statisticsCount ?? {}
        const oRect = part.oRect
        let statisticsSize = ''
        if (oRect.height > oRect.width) {
          statisticsSize = `${oRect.height}*${oRect.width}`
        } else {
          statisticsSize = `${oRect.width}*${oRect.height}`
        }
        const statisticsDimension = tempData.statisticsDimension ?? 'noLimit'
        let count = 0
        try {
          if (statisticsDimension == 'noLimit') {
            count = statisticsCount[statisticsSize]['noLimit']
          } else {
            if (statisticsDimension == 'sameRoom') {
              count =
                statisticsCount[statisticsSize]['sameRoom'][
                  `${part.orderNo ?? ''}-${part.roomName ?? ''}`
                ]
            } else if (statisticsDimension == 'sameOrder') {
              count =
                statisticsCount[statisticsSize]['sameOrder'][part.orderNo ?? '']
            } else if (statisticsDimension == 'sameWardrobe') {
              count =
                statisticsCount[statisticsSize]['sameWardrobe'][
                  `${part.orderNo ?? ''}-${part.roomName ?? ''}-${
                    part.loc ?? ''
                  }`
                ]
            }
          }
          result = `同尺寸小板${count}个`
        } catch (error) {
          result = ''
          console.error('error：' + error)
        }
      }
      break
    default:
      result = part[tempData.source_data] || ''
      break
  }
  return result + ''
}

function handleSizeNum(str) {
  if (!str) return ''
  const a = str.split('*')
  let s = ''
  a.forEach((item) => {
    let num = Number(item).toFixed(3).toString()
    num = num.substring(0, num.length - 1)
    s = `${s}*${parseFloat(num)}`
  })
  s = s.slice(1)
  return s
}

function countEdgeInfoByJXType(part) {
  let edgeInfo = part.edgeInfo.split(/←|↓|→|↑/).filter((v) => v)
  // return edgeInfo
  if (!JX_CHECKED) return edgeInfo

  let xyReverse = NC_SETTING.xyReverse ? NC_SETTING.xyReverse : false
  if (xyReverse) {
    let copyEdgeV = JSON.parse(JSON.stringify(edgeInfo))[1]
    edgeInfo[1] = edgeInfo[3]
    edgeInfo[3] = copyEdgeV
  } else {
    let copyEdgeV = JSON.parse(JSON.stringify(edgeInfo))[0]
    edgeInfo[0] = edgeInfo[2]
    edgeInfo[2] = copyEdgeV
  }
  return edgeInfo
}

function drawBigPlank(
  doc,
  plank,
  part,
  part_index,
  info,
  rectL,
  rectT,
  startX,
  startY,
  template,
  sort_value,
  finalScale
) {
  if (!info.mWidth || !info.mHeight) return

  // 由于与图片的比例不对，手动向左偏移
  const offsetLeft = 1
  let edgeInfo = countEdgeInfoByJXType(part)
  // 如果是大板标签，四条边的显示数值要发生变化
  edgeInfo = updateEdgeInfo(template, plank?.graghEdge) || edgeInfo
  let { topEdgeVal, bottomEdgeVal, leftEdgeVal, rightEdgeVal } =
    plank?.graghEdge || {}
  // return new Promise((resolve) => {
  // 获取文本宽高
  let widthS = [],
    heightS = []
  if (Array.isArray(info.textItems)) {
    info.textItems.forEach((item) => {
      if (item.positionName) {
        let text = new fabric.Text(getEdgeValue(item.positionName, edgeInfo), {
          positionName: item.positionName,
          fontSize: item.fontSize,
          fontWeight: item.fontWeight,
        })
        widthS.push(text.width)
        heightS.push(text.height)
      }
    })
  }

  // resolve({
  //   width: Math.max.apply(null, widthS),
  //   height: Math.max.apply(null, heightS),
  // })
  let rectMsg = {
    width: Math.max.apply(null, widthS),
    height: Math.max.apply(null, heightS),
  }
  // 大板标签不需要基准
  if (FIVE_SIX_DIR && template.type !== 'plank')
    drawFiveSixDir(doc, template, part, info, startX, startY)
  // 根据文本宽高计算排版图偏移量
  let textHeight = rectMsg.height
  let textWidth = rectMsg.width
  let gW = mmToPx(info.mWidth, getDeviceXDPI())
  let gH = mmToPx(info.mHeight, getDeviceXDPI())
  // 乘以0.95是因为绘制的图像比图片中的要大
  let ScaleX =
    (gW - textWidth) /
    (NC_SETTING.xyReverse ? plank.plankHeight : plank.plankWidth)
  let ScaleY =
    (gH - textHeight) /
    (NC_SETTING.xyReverse ? plank.plankWidth : plank.plankHeight)
  let PlankScale = Math.min.apply(null, [ScaleX, ScaleY]) * 0.9
  let xyReverse = store.state.ncSetting.xyReverse
  // let drawPlankWidth = xyReverse ? plank.plankHeight : plank.plankWidth
  // let drawPlankHeight = xyReverse ? plank.plankWidth : plank.plankHeight
  let plankW = pxTomm(plank.plankWidth * PlankScale, dpi)
  let plankH = pxTomm(plank.plankHeight * PlankScale, dpi)
  doc.setFillColor(255)
  doc.setDrawColor(0)
  if (template.type === 'plank') {
    // 因为大板标签的尺寸不一样，通过是1000这个级别，所以宽度设置宽一点
    // textWidth = 20
  }
  if (template.type === 'plank') {
    // 大板的起点左边手动向左移动一点
    startX = startX - offsetLeft * 1.5
  }
  // 重新设置绘制方法
  //板件图最外的轮廓
  let maxRect = {
    x: 0,
    y: 0,
    width:
      template.type === 'plank'
        ? plankW * CONFIG.scale * finalScale
        : plankW * CONFIG.scale,
    height:
      template.type === 'plank'
        ? plankH * CONFIG.scale * finalScale
        : plankH * CONFIG.scale,
  }
  if (plank.surplusInfo && Object.keys(plank.surplusInfo).length) {
    maxRect =
      plank.surplusInfo.shape === 'lshape'
        ? dealSurplusPlank(
            plank.surplusInfo,
            NC_SETTING,
            PlankScale,
            CONFIG.scale,
            JX_CHECKED
          )
        : {
            x: 0,
            y: 0,
            width: pxTomm(
              plank.surplusInfo.width * PlankScale * CONFIG.scale,
              dpi
            ),
            height: pxTomm(
              plank.surplusInfo.height * PlankScale * CONFIG.scale,
              dpi
            ),
          }
  }
  Reflect.defineProperty(maxRect, '_mainColor', { value: 255 })
  const pathArr = []
  const regularRectArr = []
  const curveHoles = []
  const millInfo = []
  const shapePath = []
  for (let idx = 0; idx < plank.parts.length; idx++) {
    const part = plank.parts[idx]
    if (part.curveHoles) {
      // 处理异形长圆孔
      const curveHolesList = part.curveHoles.filter(
        (hole) => hole.disType != 'CCCurveSlot'
      )
      for (const hole of curveHolesList) {
        const holes = xyScale(
          hole.path,
          template.type === 'plank' ? PlankScale * finalScale : PlankScale,
          CONFIG.scale,
          part
        )
        curveHoles.push(holes)
      }
    }
    if (part.millInfo) {
      // 处理柜柜牛角槽
      for (const slot of part.millInfo) {
        const slots = xyScale(
          slot.shape,
          template.type === 'plank' ? PlankScale * finalScale : PlankScale,
          CONFIG.scale,
          part
        )
        millInfo.push(slots)
      }
    }
    // 处理异形
    if (part.path) {
      for (let i = 0; i < part.path.length; i++) {
        const element = part.path[i]
        if (i == 0) {
          const path = xyScale(
            element,
            template.type === 'plank' ? PlankScale * finalScale : PlankScale,
            CONFIG.scale,
            part
          )
          // 高亮的板件
          highPart(part, sort_value, part_index, idx, template, path)
          // 补充一个点位，防止余料不能画完
          path.push({ ...path[0] })
          pathArr.push(path)
        } else {
          const path = xyScale(
            element,
            template.type === 'plank' ? PlankScale * finalScale : PlankScale,
            CONFIG.scale,
            part
          )
          shapePath.push(path)
        }
      }
    } else {
      // 处理常规矩形
      let [x, y, width, height] = xyScale(
        [part.startX, part.startY, part.rect.width, part.rect.height],
        template.type === 'plank' ? PlankScale * finalScale : PlankScale,
        CONFIG.scale
      )
      const rect = { x, y, width, height }
      // 高亮的板件
      highPart(part, sort_value, part_index, idx, template, rect)
      regularRectArr.push(rect)
    }
  }
  const finalPath = [
    ...pathArr,
    ...regularRectArr,
    ...shapePath,
    ...curveHoles,
    ...millInfo,
  ]
  let groupStartX = startX,
    groupStartY = startY
  if (xyReverse) {
    groupStartX = startX - ((plankW - plankH) / 2) * CONFIG.scale
    groupStartY = startY + ((plankW - plankH) / 2) * CONFIG.scale
  }
  const group = new DocCombinationShape(
    maxRect,
    finalPath,
    template.type === 'plank'
      ? groupStartX + pxTomm(textWidth, dpi) * finalScale
      : groupStartX + pxTomm(textWidth, dpi),
    template.type === 'plank'
      ? groupStartY + pxTomm(textHeight, dpi) * finalScale
      : groupStartY + pxTomm(textHeight, dpi)
  )
  /**
   * 绑定this
   * 记录板件图周边信息 rotateNum  记录板件旋转了多少次，向什么方向旋转 如 -90° rotateNum -= 1
   */
  const drawInfoObj = {
    aroundInfo: edgeInfo,
    tempInfo: info,
    rotateNum: 0,
    part,
    xyReverse,
    doc,
    type: template.type,
  }
  // 大板标签四周信息是板件宽高 非大板标签为封边值
  if (template.type === 'plank') {
    // L型余料
    if (part.surplusInfo && part.surplusInfo.shape == 'lshape') {
      part.surplusInfo.shape == 'lshape'
      const surplus = part.surplusInfo
      drawInfoObj.aroundInfo = xyReverse
        ? [surplus.width, surplus.height, surplus.x4, surplus.y4]
        : [surplus.height, surplus.width, surplus.y4, surplus.x4]
    } else {
      // 矩形余料以及其他板件
      drawInfoObj.aroundInfo = xyReverse
        ? [plank.plankWidth, plank.plankHeight, '', '']
        : [plank.plankHeight, plank.plankWidth, '', '']
    }
  }

  if (info.rotateDeg || info.rotateDeg == 0) {
    if (xyReverse) {
      setRotate(group, drawInfoObj, -90)
    }
    if (info.rotateDeg) {
      for (let index = 0; index < info.rotateDeg / 90; index++) {
        setRotate(group, drawInfoObj, 90)
      }
    }
  } else {
    // xy互换逆时针旋转90°
    if (xyReverse) {
      setRotate(group, drawInfoObj, -90)
    }
    // 模板设置逆时针旋转90°
    if (isRotatePlank) {
      setRotate(group, drawInfoObj, -90)
    }
  }
  const drawEdgeCb = drawEdgeAndOtherInfo.bind(
    drawInfoObj,
    template.type === 'plank' ? finalScale : 1
  )
  group.drawGroup(doc, drawEdgeCb)
}

/**
 *
 * @param {string} plankNum 生成条形码的字符串
 * @param {object} tempData 当前所需显示的模板
 * @returns
 */
function drawBrCode(plankNum, tempData) {
  let canvas = document.createElement('canvas')
  JsBarcode(canvas, plankNum, {
    format: tempData.code_type.toUpperCase(),
    width: tempData.mHeight * 0.3 * CONFIG.scale,
    height: tempData.mWidth * 2.5 * CONFIG.scale,
    margin: 0,
    displayValue: false,
    // fontOptions: tempData.fontWeight === 'bold' ? 'bold' : '',
    // fontSize: tempData.fontSize * 2.5 * CONFIG.scale,
    // textMargin: tempData.lineHeight
  })
  const OneCodeUrl = canvas.toDataURL('image/png', {
    quality: 1,
    format: 'jpeg',
  })
  return {
    url: OneCodeUrl,
    height: tempData.mHeight * 0.3 * CONFIG.scale,
    width: tempData.mWidth * 2.5 * CONFIG.scale,
  }
}

/**
 *
 * @param {string} url 二维码路径
 * @param {object} tempData 当前所需要显示的模板
 * @returns
 */
async function drawQrCode(url, tempData, doc, x, y, w, h) {
  if (tempData.QRCodeFprmat == 'DMCode') {
    let canvas = document.createElement('canvas')
    try {
      BwipJs.toCanvas(canvas, {
        bcid: 'datamatrix', // Barcode type
        text: url, // Text to encode
        scale: 1, // 3x scaling factor
        width: pxTomm(tempData.mWidth, dpi) * CONFIG.scale,
        height: pxTomm(tempData.mHeight, dpi) * CONFIG.scale,
      })
      const uri = canvas.toDataURL('image/png')
      doc.addImage(uri, x, y, w, h)
    } catch (e) {
      // `e` may be a string or Error object
      console.error(e)
    }
  } else {
    QRCode.toDataURL(url, {
      height: pxTomm(tempData.mHeight, dpi) * CONFIG.scale,
      width: pxTomm(tempData.mWidth, dpi) * CONFIG.scale,
      margin: 0,
      quality: 5,
      errorCorrectionLevel: 'H',
      format: 'jpeg',
    }).then((url) => {
      doc.addImage(url, x, y, w, h)
    })
  }
}

/**
 *
 * @param {object} doc pdf文檔對象
 * @param {object} template 模板
 * @param {object} part 當前小板
 * @param {object} info 當前模板
 */
function drawFiveSixDir(doc, template, part, info) {
  let output56FDrillSlotDir = 'default',
    output56FDrillLayoutDir = 'Vertical'
  if (
    NC_SETTING.hasOwnProperty('output56FDrillSlotDir') &&
    NC_SETTING.hasOwnProperty('output56FDrillLayoutDir')
  ) {
    output56FDrillSlotDir = NC_SETTING.output56FDrillSlotDir
    output56FDrillLayoutDir = NC_SETTING.output56FDrillLayoutDir
  }
  let spin_num = change_plank_dir(
    part,
    {
      ...NC_SETTING,
      output56FDrillSlotDir,
      output56FDrillLayoutDir,
      panelSize: NC_SETTING.panelSize,
      xyReverse: NC_SETTING.xyReverse || false,
    },
    info.plankWidth,
    info.plankHeight
  )
  let path = []
  // 获取标签宽高
  let width = template.tag_width * CONFIG.scale
  let height = template.tag_height * CONFIG.scale
  /** 基础数据都进行过微调 */
  let min = pxTomm(4, dpi),
    max = pxTomm(21, dpi),
    mid = pxTomm(20, dpi),
    top = 0,
    left = 0
  switch (spin_num) {
    case 0:
      path = [
        { x: 0, y: height - max },
        { x: min, y: height - max },
        { x: min, y: height - min },
        { x: max, y: height - min },
        { x: max, y: height },
        { x: 0, y: height },
        { x: 0, y: height - max },
      ]
      top = height - max
      left = 0
      break
    case 1:
      path = [
        { x: 0, y: 0 },
        { x: 0, y: max },
        { x: min, y: max },
        { x: min, y: min },
        { x: max, y: min },
        { x: max, y: 0 },
        { x: 0, y: 0 },
      ]
      top = 0
      left = 0
      break
    case 2:
      path = [
        { x: width - max, y: 0 },
        { x: width, y: 0 },
        { x: width, y: max },
        { x: width - min, y: max },
        { x: width - min, y: min },
        { x: width - max, y: min },
        { x: width - max, y: 0 },
      ]
      top = 0
      left = width - max
      break
    case 3:
      path = [
        { x: width - min, y: height - max },
        { x: width, y: height - max },
        { x: width, y: height },
        { x: width - max, y: height },
        { x: width - max, y: height - min },
        { x: width - min, y: height - min },
        { x: width - min, y: height - max },
      ]
      top = height - max
      left = width - max
      break
    default:
      path = [
        { x: 0, y: 0 },
        { x: 0, y: max },
        { x: max, y: max },
        { x: max, y: mid },
        { x: min, y: mid },
        { x: min, y: 0 },
        { x: 0, y: 0 },
      ]
      top = 0
      left = 0
      break
  }
  path.forEach((point, idx) => {
    if (idx === 0) {
      doc.moveTo(point.x, point.y)
    } else {
      doc.lineTo(point.x, point.y)
    }
  })
  doc.setFillColor(0)
  doc.fillStroke()
}

/**
 * @description 生成余料标签
 * @param template 模板数据
 * @param cut_part 当前小板信息
 * @param plank 当前大板
 * @param plank_index 当前第几张大板
 * @param total_length 小板总数
 * @param nc_setting this.$store.state.ncSetting
 */
async function new_createLavePdf(
  doc,
  template,
  cut_part,
  plank,
  plank_index,
  total_length,
  nc_setting,
  label_num
) {
  NC_SETTING = nc_setting || {}
  let arrPI = getDeviceXDPI()
  // 获取余料边长信息
  cut_part.plankSize = getSurplusSize(
    cut_part.surplusPath[0],
    nc_setting,
    cut_part.stockKey
  ).str
  if (sessionStorage.getItem('thinkerx_material')) {
    cut_part.plankSize += `*1`
  } else {
    cut_part.plankSize += `*${plank.thick}`
  }

  if (!template?.surplus_data) {
    // 设置为默认模板
    template.surplus_data = JSON.parse(defaultTagData).surplus_tem_data
  }

  await template.surplus_data.forEach(async (item) => {
    const info = item.data
    let rectL = template.rectInfo.left
    let rectT = template.rectInfo.top
    let x = pxTomm(info.left - rectL, dpi)
    let y = pxTomm(info.top - rectT, dpi)
    // 设置字体的大小
    doc.setFontSize(info.fontPt - 1)
    let str = ''
    switch (item.type) {
      case 'FixedText':
        str = info.text
        doc.setFont(fontFamilyMap[info.fontFamily])
        break
      case 'DataSource':
        let textArr = plank.plankSize.split('*')
        if (plank.surplusInfo?.isNotSurplus || !plank.surplusInfo) {
          textArr[0] = plank.plankHeight
          textArr[1] = plank.plankWidth
        }
        const infoStr = {
          oSize: cut_part.plankSize,
          oSize_L: cut_part.plankSize
            .split('*')
            .splice(0, cut_part.plankSize.split('*').length - 1)
            .join('*'),
          matCode: cut_part.matCode,
          bigPlankID: plank_index + '',
          plank_index: `第${plank_index}张大板`,
          lable_index: `第${label_num + 1}/${total_length}页`,
          paibanTime: getPaibanTime(),
          oriPlankNum: cut_part.oriPlankNum,
          plankSize: `${textArr[1]}*${textArr[0]}*${textArr[2]}`,
          texture: cut_part.texture,
          // 所在余料库
          branch_no: cut_part.branch_name || '-',
        }
        str = infoStr[info.source_data] ?? ''
        if (!str) break
        const { text: newStr, fontSize: fts } = dealTextStr(
          str,
          info.fontSize,
          info.rectWidth ?? 80,
          info.rectHeight ?? 11.3,
          info.isAutoFontSize ?? true
        )
        str = newStr
        doc.setFont(fontFamilyMap[info.fontFamily])
        doc.setFontSize(PxToPt(fts))
        break
      case 'QRcode':
        let url = ''
        if (info.source_data == 'oriPlankNum') {
          url = cut_part[info.source_data]
        } else if (info.source_data == 'restockingCode') {
          let uid = store.state.userInfo.id
          let page = 1
          let { shape, max_long, max_width, min_long, min_width } =
            getSurplusSize(
              cut_part.surplusPath[0],
              nc_setting,
              cut_part.stockKey
            ) //余料形状 1：L形 2：矩形
          let plankInfo = {
            uid,
            m: cut_part.matCode,
            t: cut_part.texture,
            s: `${page}-${max_long}-${min_long}-${max_width}-${min_width}-${cut_part.thick}-${shape}`,
          }
          url = `http://eggi.cn/?plankInfo=${JSON.stringify(plankInfo)}`
        }
        if (!url) break
        await drawQrCode(url, info, doc, x, y, info.mWidth, info.mHeight)

        // await QRCode.toDataURL(url, {
        //   height: pxTomm(info.mHeight, dpi) * CONFIG.scale,
        //   width: pxTomm(info.mWidth, dpi) * CONFIG.scale,
        //   margin: 0,
        //   quality: 5,
        //   errorCorrectionLevel: 'H',
        //   format: 'jpeg',
        // }).then((url) => {
        //   doc.addImage(url, x, y, info.mWidth, info.mHeight)
        // })
        break
      case 'Marking':
        drawWardrobeNum(doc, info, x, y, '余')
        break
      case 'Typography':
        let ScaleX = mmToPx(info.mWidth) / plank.plankWidth
        let ScaleY = mmToPx(info.mHeight) / plank.plankHeight
        if (NC_SETTING.xyReverse) {
          ScaleX = mmToPx(info.mWidth) / plank.plankHeight
          ScaleY = mmToPx(info.mHeight) / plank.plankWidth
        }
        let plank_scale = Math.min.apply(null, [ScaleX, ScaleY])
        let xyReverse = NC_SETTING.xyReverse
        let startY = pxTomm(info.top - rectT, dpi) * CONFIG.scale
        let startX = pxTomm(info.left - rectL, dpi) * CONFIG.scale
        // 板件逆时针旋转后需要调整一下起始点
        // if (isRotatePlank) {
        //   startY = pxTomm(5, dpi)
        //   startX -= pxTomm(10, dpi)
        // }
        const plankW = pxTomm(plank.plankWidth * plank_scale, dpi)
        const plankH = pxTomm(plank.plankHeight * plank_scale, dpi)
        // 余料大板绘制
        let maxRect = {
          x: 0,
          y: 0,
          width: plankW * CONFIG.scale,
          height: plankH * CONFIG.scale,
        }
        if (plank.surplusInfo && Object.keys(plank.surplusInfo).length) {
          maxRect =
            plank.surplusInfo.shape === 'lshape'
              ? dealSurplusPlank(
                  plank.surplusInfo,
                  NC_SETTING,
                  plank_scale,
                  CONFIG.scale,
                  JX_CHECKED
                )
              : {
                  x: 0,
                  y: 0,
                  width: pxTomm(
                    plank.surplusInfo.width * plank_scale * CONFIG.scale,
                    dpi
                  ),
                  height: pxTomm(
                    plank.surplusInfo.height * plank_scale * CONFIG.scale,
                    dpi
                  ),
                }
        }
        Reflect.defineProperty(maxRect, '_mainColor', { value: 255 })
        const pathArr = []
        const curveHoles = []
        const millInfo = []
        const shapePath = []
        for (let idx = 0; idx < plank.parts.length; idx++) {
          const part = plank.parts[idx]
          if (part.curveHoles) {
            // 处理异形长圆孔
            for (const hole of part.curveHoles) {
              const holes = xyScale(hole.path, plank_scale, CONFIG.scale, part)
              curveHoles.push(holes)
            }
          }
          if (part.millInfo) {
            // 处理柜柜牛角槽
            for (const slot of part.millInfo) {
              const slots = xyScale(slot.shape, plank_scale, CONFIG.scale, part)
              millInfo.push(slots)
            }
          }
          // 处理异形
          if (part.path) {
            for (let i = 0; i < part.path.length; i++) {
              const element = part.path[i]
              if (i == 0) {
                const path = xyScale(element, plank_scale, CONFIG.scale, part)
                // 高亮的板件
                if (part.plankNum == cut_part.plankNum) {
                  Object.defineProperty(path, 'special', {
                    value: 102,
                    writable: true,
                    enumerable: false,
                  })
                }
                // 补充一个点位，防止余料不能画完
                path.push({ ...path[0] })
                pathArr.push(path)
              } else {
                const path = xyScale(element, plank_scale, CONFIG.scale, part)
                if (part.plankNum == cut_part.plankNum) {
                  Object.defineProperty(path, 'special', {
                    value: 102,
                    writable: true,
                    enumerable: false,
                  })
                }
                shapePath.push(path)
              }
            }
          } else {
            // 处理常规矩形
            let [x, y, width, height] = xyScale(
              [part.startX, part.startY, part.rect.width, part.rect.height],
              plank_scale,
              CONFIG.scale
            )
            const rect = { x, y, width, height }
            // 高亮的板件
            if (part.plankNum == cut_part.plankNum) {
              Object.defineProperty(rect, 'special', {
                value: 102,
                writable: true,
                enumerable: false,
              })
            }
            pathArr.push(rect)
          }
        }

        const finalPath = [...pathArr, ...shapePath, ...curveHoles, ...millInfo]

        let groupStartX = startX,
          groupStartY = startY
        if (xyReverse) {
          groupStartX = startX - ((plankW - plankH) / 2) * CONFIG.scale
          groupStartY = startY + ((plankW - plankH) / 2) * CONFIG.scale
        }

        const group = new DocCombinationShape(
          maxRect,
          finalPath,
          groupStartX,
          groupStartY
        )
        // if (nc_setting.xyReverse) group.rotate(-90)
        // if (isRotatePlank) group.rotate(-90)
        rotateKlass(group, info, nc_setting.xyReverse, isRotatePlank)
        group.drawGroup(doc)
        break
      case 'Onecode':
        let plankNumV = '',
          simplePlankNum = cut_part.simplePlankNum
        if (info.source_data === 'oriPlankNum') {
          plankNumV = cut_part.oriPlankNum
        } else break
        if (!plankNumV) break
        plankNumV += ''
        if (
          nc_setting.genSimpleLabelBarcode &&
          info.source_data !== 'ncName' &&
          info.source_data !== 'remark'
        ) {
          plankNumV = simplePlankNum
        }
        try {
          const brcode = drawBrCode(plankNumV, info)
          /** 高度 * 0.75 主要是如果生成条形码不给文字，默认高度会占100% 文字将会放不下 */
          // 宽度 * 0.98 是因为pdf生成的条形码会比图片中二维码要长一点
          let rX = x
          let rY = y
          const scaleX = info.mWidth / 32.54
          const scaleY = info.mHeight / 9.52
          const textScale = scaleX < scaleY ? scaleX : scaleY
          const textWidth = doc.getStringUnitWidth(plankNumV)
          /** 不能确定文本的宽度 所以从条形码的宽度4/1开始画 */
          const d = info.mWidth / 4 - textWidth / 2 // 条码左侧距条码左侧的距离
          let textStartX = x + d + 2.2
          /** 文本的高度也不能确定  所以在画出来的基础上让文字多向下1.5mm*/
          let textStartY =
            y + info.mHeight * 0.75 + info.lineHeight + 1.5 * textScale
          let rotateDeg = info.rotateDeg
          const { mHeight, mWidth } = info
          const mh = mHeight * 0.75
          const mw = mWidth * 0.98
          switch (info.rotateDeg) {
            case 90:
              rotateDeg = -90
              rX = x + (mw - mh) / 2
              rY = y - (mw + mh) / 2
              textStartX = rX - 1.5 * 2
              textStartY = rY + mh + d + 1.5
              break
            case 180:
              rX = x + mw
              rY = y - mh
              textStartX = rX - d - textWidth
              textStartY = rY + (mh - 1.5 * 2)
              rotateDeg = 180
              break
            case 270:
              rotateDeg = 90
              rX = x + (mw + mh) / 2
              rY = y + (mw - mh) / 2
              textStartX = rX + 1.5 * 2
              textStartY = rY + mh - d - 1.5
              break
            default:
              break
          }

          doc.addImage(
            brcode.url,
            rX,
            rY,
            info.mWidth * 0.98,
            info.mHeight * 0.75,
            '',
            '',
            rotateDeg
          )
          // ctx.rotate(-90)

          doc.setFont(fontFamilyMap[info.fontFamily])
          doc.setFontSize((info.fontPt - 1) * textScale)
          doc.text(plankNumV, textStartX, textStartY, {
            angle: rotateDeg,
          })
        } catch (error) {
          console.error(error)
        }
        break
      case 'SurplusSize':
        // 获取余料各边位置
        let notchDirArr = dealNotchDir(cut_part.surplusPath[0])
        // 绘制当前板件信息
        const cutPartDrawPoint = {
          left: pxTomm(info.left - rectL, dpi),
          top: pxTomm(info.top - rectT, dpi),
        }
        let cutSizeArr = cut_part.cutSize?.split('*')
        let scaleX = mmToPx(info.mWidth, arrPI) / cutSizeArr[1]
        let scaleY = mmToPx(info.mHeight, arrPI) / cutSizeArr[0]
        let SurplusSizeScale = Math.min.apply(null, [scaleX, scaleY])
        const drawPoint = cut_part.surplusPath[0].map((it) => ({
          x: pxTomm(it.x * SurplusSizeScale * CONFIG.scale, dpi),
          y: pxTomm(it.y * SurplusSizeScale * CONFIG.scale, dpi),
        }))
        drawPoint.push({ ...drawPoint[0] })
        const cutPart = new DocCombinationShape(
          drawPoint,
          [],
          cutPartDrawPoint.left,
          cutPartDrawPoint.top
        )
        if (isRotatePlank) cutPart.rotate(-90)
        cutPart.drawGroup(doc, drawAround)
        function drawAround(doc, pointArr) {
          const diameterR = getPlateKnifeDiameter(
            cut_part.stockKey,
            store.state.ncSetting
          )
          // let knifeR = nc_setting.knife.diameter
          // if (nc_setting.glass_setting) knifeR = 0
          // 当数组长度不足4表示是一个矩形，只需要考虑bottom，right
          if (notchDirArr.length == 2) notchDirArr = ['', ...notchDirArr, '']
          doc.setFontSize(info.textItems[0].fontPt * CONFIG.scale)
          doc.setFont(fontFamilyMap[info.textItems[0].fontFamily])
          let LE, BE, RE, TE
          ;[LE, BE, RE, TE] = notchDirArr.map((it) => it.text ?? '')
          const dirArr = ['left', 'bottom', 'right', 'top']
          // 记录当前那条边在左边
          let atLeft = null
          let index = 0
          for (const location of pointArr) {
            let { x, y, location: dir } = location
            let str = '',
              textBaseline = 'top'
            switch (dir) {
              case 'left':
                str = LE
                break
              case 'bottom':
                str = BE
                break
              case 'right':
                str = RE
                break
              case 'top':
                str = TE
                break
            }
            if (str) str = parseInt(Number(str).toFixed(2)) - diameterR
            str += ''
            let { offsetWidth, offsetHeight } = dealElementWH(str, 8)
            if (!atLeft) {
              let num = 0
              if (isRotatePlank) num = -1
              const res = getArrayItem(dirArr, 0, num)
              ;[index, atLeft] = [res.index, res.res]
            }
            // 0.5是微调
            if (dir === atLeft) {
              x -= offsetWidth
              y -= offsetHeight / 2
            } else if (dir === getArrayItem(dirArr, index, 1).res) {
              x -= offsetWidth / 2
              y += 0.5
            } else if (dir === getArrayItem(dirArr, index, -1).res) {
              x -= offsetWidth / 2
              y -= offsetHeight - 0.5
            } else {
              y -= offsetHeight / 2
            }
            if (str) {
              doc.text(str, x, y, { baseline: textBaseline })
            }
          }
        }
        break
    }
    if (str) {
      doc.text(str, x, y, { baseline: 'top' })
    }
  })
}

/**
 * @description 生成余料标签
 * @param template 模板数据
 * @param plank 大板信息
 * @param plank_index 第几张大板
 * @param config 配置（放大倍数 scale, 画布颜色 backgroundColor）
 * @param nc_setting this.$store.state.ncSetting
 */
async function createLavePdf(
  doc,
  template,
  plank,
  plank_index,
  config = {},
  nc_setting,
  total_part,
  part_page
) {
  return new Promise((resolve) => {
    CONFIG = initConfig(config)
    NC_SETTING = nc_setting || {}

    // let canvas_width = template.tag_width
    // let canvas_height = template.tag_height
    let arrPI = getDeviceXDPI()
    // let plank_scale =
    //   (mmToPx(canvas_height, arrPI) * 2) /
    //   3 /
    //   (NC_SETTING.xyReverse ? plank.plankWidth : plank.plankHeight)
    // const plankW = pxTomm(plank.plankWidth * plank_scale, dpi)
    // const plankH = pxTomm(plank.plankHeight * plank_scale, dpi)
    if (!template?.surplus_data) {
      // 设置为默认模板
      template.surplus_data = JSON.parse(defaultTagData).surplus_tem_data
    }
    template.surplus_data.forEach((item) => {
      const info = item.data
      let rectL = template.rectInfo.left
      let rectT = template.rectInfo.top
      let x = pxTomm(info.left - rectL, dpi)
      let y = pxTomm(info.top - rectT, dpi)
      let ScaleX = mmToPx(info.mWidth) / plank.plankWidth
      let ScaleY = mmToPx(info.mHeight) / plank.plankHeight
      if (NC_SETTING.xyReverse) {
        ScaleX = mmToPx(info.mWidth) / plank.plankHeight
        ScaleY = mmToPx(info.mHeight) / plank.plankWidth
      }
      let plank_scale = Math.min.apply(null, [ScaleX, ScaleY])
      doc.setFontSize(info.fontPt - 1)
      let str = ''
      switch (item.type) {
        case 'FixedText':
          str = info.text
          doc.setFont(fontFamilyMap[info.fontFamily])
          break
        case 'DataSource':
          const infoStr = {
            oSize: '',
            oSize_L: '',
            matCode: plank.matCode,
            bigPlankID: plank_index + 1 + '',
            plank_index: `第${plank_index + 1}张大板`,
            lable_index: `第${part_page + plank_index + 1}/${total_part}页`,
            paibanTime: getPaibanTime(),
            oriPlankNum: '',
            plankSize: `${plank.plankHeight}*${plank.plankWidth}*${plank.thick}`,
            texture: plank.texture,
            branch_no: '-',
          }
          str = infoStr[info.source_data] ?? ''
          if (!str) break
          const { text: newStr, fontSize: fts } = dealTextStr(
            str,
            info.fontSize,
            info.rectWidth ?? 80,
            info.rectHeight ?? 11.3,
            info.isAutoFontSize ?? true
          )
          str = newStr
          doc.setFont(fontFamilyMap[info.fontFamily])
          doc.setFontSize(PxToPt(fts))
          break
        // 生成余料标签的二维码（废料标签）
        case 'QRcode':
          let url = ''
          if (info.source_data == 'oriPlankNum') {
            break
          } else if (info.source_data == 'restockingCode') {
            let uid = store.state.userInfo.id
            let page = 2
            let shape = 2
            let plankInfo = {
              uid,
              m: plank.matCode,
              t: plank.texture,
              s: `${page}-${plank.thick}-${shape}`,
            }
            url = `http://eggi.cn/?plankInfo=${JSON.stringify(plankInfo)}`
          }
          if (!url) break
          // 根据url绘制二维码
          drawQrCode(url, info, doc, x, y, info.mWidth, info.mHeight)
          // QRCode.toDataURL(url, {
          //   height: pxTomm(info.mHeight, dpi) * CONFIG.scale,
          //   width: pxTomm(info.mWidth, dpi) * CONFIG.scale,
          //   margin: 0,
          //   quality: 5,
          //   errorCorrectionLevel: 'H',
          //   format: 'jpeg',
          // }).then((url) => {
          //   doc.addImage(url, x, y, info.mWidth, info.mHeight)
          // })
          break
        case 'Marking':
          drawWardrobeNum(doc, info, x, y, '余')
          break
        case 'Typography':
          let xyReverse = NC_SETTING.xyReverse
          let startY = pxTomm(info.top - rectT, dpi) * CONFIG.scale
          let startX = pxTomm(info.left - rectL, dpi) * CONFIG.scale
          const plankW = pxTomm(plank.plankWidth * plank_scale, dpi)
          const plankH = pxTomm(plank.plankHeight * plank_scale, dpi)
          let parts = plank.parts
          const maxRect = {
            x: 0,
            y: 0,
            width: plankW * CONFIG.scale,
            height: plankH * CONFIG.scale,
          }
          Object.defineProperty(maxRect, 'special', {
            value: 102,
            writable: true,
            enumerable: false,
          })
          const shapePaths = []
          parts.forEach((part) => {
            if (part.path) {
              let path = part.path[0].map((v) => {
                return {
                  x:
                    pxTomm((part.startX + v.x) * plank_scale, dpi) *
                    CONFIG.scale,
                  y:
                    pxTomm((part.startY + v.y) * plank_scale, dpi) *
                    CONFIG.scale,
                }
              })
              path.push({ ...path[0] })
              shapePaths.push(path)
            } else {
              const rect = {
                x: pxTomm(part.startX * plank_scale * CONFIG.scale, dpi),
                y: pxTomm(part.startY * plank_scale * CONFIG.scale, dpi),
                width: pxTomm(
                  part.rect.width * plank_scale * CONFIG.scale,
                  dpi
                ),
                height: pxTomm(
                  part.rect.height * plank_scale * CONFIG.scale,
                  dpi
                ),
              }
              shapePaths.push(rect)
            }
          })
          let groupStartX = startX,
            groupStartY = startY
          if (xyReverse) {
            groupStartX = startX - ((plankW - plankH) / 2) * CONFIG.scale
            groupStartY = startY + ((plankW - plankH) / 2) * CONFIG.scale
          }
          const groupBox = new DocCombinationShape(
            maxRect,
            shapePaths,
            groupStartX,
            groupStartY
          )
          // TODO 修改
          // if (nc_setting.xyReverse) groupBox.rotate(-90)
          // if (isRotatePlank) groupBox.rotate(-90)
          rotateKlass(groupBox, info, nc_setting.xyReverse, isRotatePlank)
          groupBox.drawGroup(doc, null, 255)
          break
        case 'OneCode':
          break
        case 'SurplusSize':
          break
      }
      if (str) {
        doc.text(str, x, y, { baseline: 'top' })
      }
    })
  })
}

function drawWardrobeNum(doc, tempData, x, y, wardrobeNum) {
  wardrobeNum += ''
  const r = pxTomm(tempData.radius, dpi)
  x += r
  y += r
  doc.setFont(fontFamilyMap[tempData.fontFamily])
  // const ra = pxTomm(tempData.mRadius, dpi)
  doc.circle(x, y, r)
  let { offsetWidth, offsetHeight } = dealElementWH(
    wardrobeNum,
    Number(tempData.fontPt),
    false
  )
  doc.text(x - offsetWidth / 2, y - offsetHeight / 2, wardrobeNum, {
    baseline: 'top',
  })
}

function drawGraphics(doc, info, startX, startY, left, top) {
  let points = info.points
  let Polygon = new fabric.Polygon(points, {
    fill: info.fill,
    scaleX: info.scaleX,
    scaleY: info.scaleY,
    angle: info.angle,
    originX: 'center',
    originY: 'center',
    selectable: false,
  })
  const pointArrX = []
  const pointArrY = []
  points.forEach((item) => {
    pointArrX.push(item.x)
    pointArrY.push(item.y)
  })

  let minx = Math.min(...pointArrX),
    maxx = Math.max(...pointArrX)
  let miny = Math.min(...pointArrY),
    maxy = Math.max(...pointArrY)
  let sx = pxTomm((maxx - minx) / 2, dpi)
  let sy = pxTomm((maxy - miny) / 2, dpi)
  // 将中心点change至左上角
  doc.addImage(Polygon.toDataURL(), startX - sx, startY - sy)
}
function rotateKlass(groupBox, info, xyReverse, isRotatePlank) {
  if (info.rotateDeg) {
    if (xyReverse) {
      groupBox.rotate(info.rotateDeg - 90)
    } else {
      groupBox.rotate(info.rotateDeg)
    }
  } else {
    // xy互换逆时针旋转90°
    if (xyReverse) {
      groupBox.rotate(-90)
    }
    // 模板设置逆时针旋转90°
    if (isRotatePlank) {
      groupBox.rotate(-90)
    }
  }
}
function drawColorRect(top, left, width, height, fillColor, doc) {
  const drawPoint = [
    {
      x: 0,
      y: 0,
    },
    {
      x: width,
      y: 0,
    },
    {
      x: width,
      y: height,
    },
    {
      x: 0,
      y: height,
    },
    {
      x: 0,
      y: 0,
    },
  ]
  defineObjProp(drawPoint, 'hiddenBorder', true)
  const rect = new DocCombinationShape([], [drawPoint], left, top)
  rect.drawGroup(doc, '', fillColor)
}
function scaleSize(size) {
  let configScale = 1
  return size * configScale
}
